























































































































































import { Component, Mixins } from 'vue-property-decorator'
import Ticket16 from '@carbon/icons-vue/es/ticket/16'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import View16 from '@carbon/icons-vue/es/view/16'
import Report16 from '@carbon/icons-vue/es/report/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import isEmpty from 'lodash/isEmpty'
import snakeCaseKeys from 'snakecase-keys'
import debounce from 'lodash/debounce'

import TablePagination from '@/partials/tables/Pagination.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    TablePagination,
    TrashCan16,
    Ticket16,
    Report16,
    View16,
    Edit16,
    GenericModalDelete
  }
})
export default class EventSubscribers extends Mixins(FlashMessageMixin, PermissionsMixin) {
  eventId = this.$route.params.id
  subscribers: Array<any> = []
  filteredData: Array<any> = []
  deleteModalIsOpened = false
  messageModalIsOpened = false
  totalSubscribers = 0
  selectedUser = {
    id: '',
    eventUsers: {
      0: {
        id: '',
        subscriptions: [] as any
      }
    }
  }

  errorMessage = ''

  fetchSubscribers (page = 1, perPage = 20, search = '') {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get(`/event/${this.$route.params.id}/users/subscribers`, {
      params: {
        'page[number]': page,
        search,
        ...perPageSnake
      }
    })
      .then(response => {
        this.subscribers = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
        this.totalSubscribers = response.data.total
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'O Inscrito foi removido com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetchSubscribers()
  }

  get deleteRequestBody () {
    return { eventUsers: [isEmpty(this.selectedUser) ? '' : this.selectedUser.eventUsers['0'].id] }
  }

  hasSubcription (user: any) {
    return user.eventUsers?.['0']?.subscriptions.length > 0
  }

  redirectToTicketOperations () {
    this.$router.push({
      name: 'EventSubscriptionOperations',
      params: {
        eventId: this.$route.params.id,
        subscriptionId: this.selectedUser.eventUsers['0'].subscriptions[0].id
      }
    })
  }

  getProfileData (profile: Array<ProfileItem>, metaKey: string) {
    return profile.find((item) => item.metaKey === metaKey)?.metaValue
  }

  search = debounce((searchString) => {
    this.fetchSubscribers(1, 20, searchString)
  }, 300)

  onPageChange (pagination: Pagination) {
    this.fetchSubscribers(pagination.page, pagination.length)
  }
}
